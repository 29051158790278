import React from 'react';
import {request, request_file} from './request.js';
import Descargas from './Descargas.js';
import PieDePagina2 from './Footer2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../images/logo1.png';
import './style.css';
import './animate.min.css';
import Swal from 'sweetalert2';

class Extraordinarios extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state={
			usuario :
			{
				id : '',
				num_control : '',
				pagoextra : '',
			},
			extraordinario :
			{
				id : '',
				num_control: '',
				materia : '',
				semestre : '',
				docente : '',
				calificacion : '',
				solicitar : ''
			},
			imprimir : false,
			salir : false,
			solicitudes : [],
			solicitadas : 0 ,
		}
	}

	componentDidMount()
	{
		var { usuario, solicitudes, solicitadas, imprimir } = this.state;
		let temp = this;

		usuario.id = this.props.id;
		usuario.pagoextra = this.props.pagoextra;

		request.get('/buscarExtraordinarios/'+usuario.id)
		.then(function(response)
		{
			if(response.status === 200)
			{
				solicitudes = response.data;
				if(solicitudes[0] !== null)
				{
					if(parseInt(solicitudes[0].desactivar,10)===1) { imprimir=true; }
				}
				temp.setState({ solicitudes : solicitudes, imprimir : imprimir, solicitadas : solicitadas });
			}
		})
		.catch(error => { });
	}

	imprimirReferencia=()=>
	{
		let { solicitudes } = this.state;

		request_file()
		.post(`/referenciaExtraordinario?num_control=${solicitudes[0].num_control}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	imprimirImpuesto=()=>
	{
		let { solicitudes } = this.state;

		request_file()
		.post(`/referenciaImpuesto?num_control=${solicitudes[0].num_control}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	imprimirSolicitud=()=>
	{
		let { usuario, solicitudes } = this.state;

		// if(parseInt(usuario.pagoextra,10)==1)
		// {
			request_file()
			.post(`/solicitudExtraordinario?num_control=${solicitudes[0].num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(error => {
				console.log(error);
			});
		// }
		// else
		// {
		// 	Swal.fire({
		// 	html: '<b><br>Para poder imprimir tu solicitud<br><br>' + 
		// 			  'debes entregar tu comprobante de pago<br><br>'+
		// 			  'en la oficina de recursos financieros</b><br><br>',
		// 		focusConfirm: false,
		// 		confirmButtonText: 'Ok'
		// 	});
		// }
	}

	guardarCambios=(evt)=>
	{
		var { solicitudes, solicitadas, imprimir } = this.state;
		let temp = this;

		// Swal.fire({
		// 	position: 'top-end',
		// 	type: 'error',
		// 	title: 'El periodo para solicitud de extraordinarios ha terminado.',
		// 	showConfirmButton: false,
		// 	timer: 1500
		// });

		if(solicitadas === 0)
		{
				Swal.fire({
					position: 'top-end',
					type: 'error',
					title: 'Debes seleccionar alguna materia.',
					showConfirmButton: false,
					timer: 1500
				});
		}
		else
		{
			Swal.fire({
				title: '¿Quieres guardar?',
				text: "Si lo haces ya no podras cambiarlo",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Si, guardar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.value)
				{				
					request.post('/RegistrarExtraordinarios',solicitudes)
					.then(function(response)
					{
						if(response.status === 200)
						{
							if(response.data)
							{
								imprimir = true;
								Swal.fire({
									position: 'top-end',
									type: 'success',
									title: 'Los datos fueron guardados',
									showConfirmButton: false,
									timer: 1500
								});
								temp.setState({ imprimir : imprimir });
							}
							else
							{
								Swal.fire({
									position: 'top-end',
									type: 'error',
									title: 'No se guardo la información.',
									showConfirmButton: false,
									timer: 1500
								});
							}
						}
					})
					.catch(error =>
					{
						Swal.fire({
							position: 'top-end',
							type: 'error',
							title: 'No se guardo la información.',
							showConfirmButton: false,
							timer: 1500
						});
					});
					this.setState({ imprimir : imprimir });
				}
			})
		}
	}

	solicitarMateria=(event,i)=>
	{
		var { solicitudes, solicitadas } = this.state;

		if(solicitadas===3)
		{
			Swal.fire({
				position: 'top-end',
				type: 'error',
				title: 'Solo puedes solicitar 2 materias',
				showConfirmButton: false,
				timer: 1500
			});
		}
		else
		{
			solicitudes[i].solicitar = 1;
			solicitadas = solicitadas + 1;
			this.setState({ solicitudes : solicitudes, solicitadas : solicitadas });
		}
	}

	cancelarMateria=(event,i)=>
	{
		var { solicitudes, solicitadas } = this.state;
		solicitudes[i].solicitar = 0;
		solicitadas = solicitadas - 1;
		this.setState({ solicitudes : solicitudes, solicitadas : solicitadas });
	}

	salir=()=>
	{
		var {salir} = this.state;
		salir=true;
		this.setState({salir : salir});
	}

	render()
	{
		let { salir, imprimir, usuario, solicitudes } = this.state;

		if(salir) return <Descargas {...usuario} />;

		return (
			<div className="principal">

				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" style={{ position: 'absolute', right: '40px' }} id="navbarNav">
						<div className="navbar-nav ">
							<button className="nav-item nav-link btn salir" onClick={this.salir}>
								SALIR&ensp;<span className="oi" data-glyph="account-logout" aria-hidden="true"> </span>
							</button>
						</div>
					</div>
				</nav>

				{/* CAMPOS PARA CAPTURA */}
				<div className="inicio container-fluid">
					<div className="row espacio2"> </div>
					<div className="row pb-4">
						<div className="col-12">
							<div className="row h-100 justify-content-around">
								<div className="col-12 col-sm-12 col-md-10 col-lg-10 align-self-center ">
									<p className="kau bl lg"> SOLICITUD DE EXAMEN EXTRAORDINARIO/RECURSAMIENTO</p>
									<p className="lat gr3 sm"> SELECCIONA LAS MATERIAS QUE QUIERES SOLICITAR Y PRESIONA GUARDAR </p>
									<p className="lat gr3 sm"> DESPUES IMPRIME TU REFERENCIA DE PAGO Y COMPROBANTE DE SOLICITUD. </p>
									<p className="lat am sm"> SOLO PODRAS SOLICITAR RECURSAMIENTOS DEL SEMESTRE <b>AGOSTO-DICIEMBRE 2024</b>,<br/>
															SI TIENES PENDIENTES MATERIAS DE SEMESTRES ANTERIORES,<br/>
															DEBERAS SOLICITARLAS DE MANERA PRESENCIAL EL DIA <b>6 DE ENERO</b>. </p>
								</div>
							</div>
						</div>
					</div>
					
					<div className="row justify-content-center pb-5">
						<div className="col-12 col-md-11">
							
							<div className="card text-center w-100">
								<div className="card-header izq"> 
									{
										imprimir?
											<div>
												<button className="btn btn-secondary botonpad" onClick={this.imprimirReferencia}>
													<b>Imprimir Referencia de Pago</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button>
												<button className="btn btn-secondary botonpad" onClick={this.imprimirImpuesto}>
													<b>Imprimir Referencia de Impuestos</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button>
												<button className="btn btn-warning botonpad" onClick={this.imprimirSolicitud}>
													<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button> 
											</div>
										:
											""												
									}
											<button className="btn btn-success botonpad" onClick={this.guardarCambios}>
												<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
											</button>

 								</div>
								<div className="card-body container-fluid">
									{
										!solicitudes.length ?
											<div className="row pt-2 pb-4">
												<h4 className="col-12 gr md izq"> NO TIENES MATERIAS PARA SOLICITAR </h4>
											</div>
										:
											solicitudes.map((item, i) => 
												<div key={i} className="row grupo mt-3">
													<div className="col-12">
														<div className="input-group input-group-sm mb-1">
															<div className="input-group-prepend">
																<span className="input-group-text" id="Materia">Materia:</span>
															</div>
															<input type="text" className="form-control" name="materia" value={item.materia} aria-label="Materia" aria-describedby="materia" disabled/>
														</div>
													</div>
													<div className="col-12 col-md-3">
														<div className="input-group input-group-sm mb-1">
															<div className="input-group-prepend">
																<span className="input-group-text" id="semestre">Calificacion:</span>
															</div>
															<input type="text" className="form-control" name="calificacion" value={item.calificacion} aria-label="Calificacion" aria-describedby="calificacion" disabled/>
														</div>
													</div>
													<div className="col-12 col-md-9">
														<div className="input-group input-group-sm mb-2">
															<div className="input-group-prepend">
																<span className="input-group-text" id="docente">Docente:</span>
															</div>
															<input type="text" className="form-control" name="docente" value={item.docente} placeholder="" aria-label="Docente" aria-describedby="docente" disabled/>
														</div>
													</div>
													{
														imprimir?
															parseInt(item.solicitar,10) === 1?
																<div className="col-12 izq verde">
																	<span><b>MATERIA SOLICITADA</b></span>
																</div>
															:
																""
														:
															parseInt(item.solicitar,10) === 1?
																<div>
																	<div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
																		<div className="input-group-prepend">
																			<button className="btn btn-danger mb-2" onClick={(event)=>this.cancelarMateria(event,i)}>
																				Cancelar materia&ensp;<span className="oi" data-glyph="x" aria-hidden="true"> </span>
																			</button>
																		</div>
																	</div>
																	<div className="col-12 izq verde">
																		<span><b>MATERIA SOLICITADA</b></span>
																	</div>
																</div>
															:
																<div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
																	<div className="input-group-prepend">
																		<button className="btn btn-success mb-2" onClick={(event)=>this.solicitarMateria(event,i)}>
																			Solicitar materia&ensp;<span className="oi" data-glyph="task" aria-hidden="true"> </span>
																		</button>
																	</div>
																</div>
													}
												</div>	

											)
									}
								</div>


							</div>
							

						</div>
					</div>

				</div>

				{/* PIE DE PAGINA */}

				<PieDePagina2 />

			</div>

		);
	}

}

export default Extraordinarios;