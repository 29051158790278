import React from 'react';
import './App.css';

import Principal from './Components/Principal.js';
import Descargas from './Components/Descargas.js';
import FueraDeServicio from './Components/Fueradeservicio.js';
import Solicitud from './Components/Solicitud.js';

function App() 
{
	const modulo=1;
	return (
		<div className="App">
			{ (modulo === 1) ? <Principal /> : "" }
			{ (modulo === 2) ? <Descargas /> : "" }
			{ (modulo === 3) ? <Solicitud /> : "" }
			{ (modulo === 4) ? <FueraDeServicio /> : "" }
		</div>
	);
}

export default App;
