import React from 'react';
import {Row, Col} from 'reactstrap';
import {request} from './request.js';
import Descargas from './Descargas.js';
import PieDePagina1 from './Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../images/logo1.png';
import './style.css';
import './animate.min.css';
import Swal from 'sweetalert2';

class Principal extends React.Component{

	constructor(props){
		super(props);

		this.state={
			usuario :
			{
				id : '',
				num_control : '',
				apellido_paterno : '',
				curp : '',
				fecha : '',
			},
			encontrado : 2,
		}
	}

	componentDidMount()
	{
		// Swal.fire({
		// 	title: 'AVISO',
		// html: '<b><br>Si no puedes entrar al portal' + 
		// 		  ' para inscribirte, debes esperar al periodo de inscripción para alumnos irregulares.<br><br>',
				  
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok'
		// });

		// Swal.fire({
		// 	html: '<b><br>El portal de reinscripciones se habilitara a partir del<br><br>' + 
		// 		  '<b>LUNES 8 </b>de <b>Febrero </b> a las <b>7:00 a.m.</b><br><br>',
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok'
		// });
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var {usuario, encontrado} = this.state;

		usuario[name] = value;
		encontrado=2;

		this.setState({ usuario : usuario, encontrado : encontrado });
	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var {usuario, encontrado} = this.state;
		let temp = this;

		// Busqueda por matricula y apellido

		// request.get('/buscar/'+usuario.num_control+'/'+usuario.apellido_paterno)
		// .then(function(response)
		// {
		// 	if(response.status === 200)
		// 	{
		// 		usuario = response.data;
		// 		if(usuario.apellido_paterno != null)
		// 		{
		// 			encontrado = 1
		// 			temp.setState({ usuario : usuario, encontrado : encontrado });
		// 		}
		// 		else
		// 		{
		// 			encontrado = 0;
		// 			temp.setState({ encontrado : encontrado });
		// 		}
		// 	}
		// })
		// .catch(error => { });

		//Buscar por matricula y curp

		request.get('/buscarcurp/'+usuario.num_control+'/'+usuario.curp)
		.then(function(response)
		{
			if(response.status === 200)
			{
				usuario = response.data;
				if(usuario.apellido_paterno != null)
				{
					encontrado = 1
					temp.setState({ usuario : usuario, encontrado : encontrado });
				}
				else
				{
					encontrado = 0;
					temp.setState({ encontrado : encontrado });
				}
			}
		})
		.catch(error => { });

		// Llamada para acceso rapido, solo con número de ficha

		// request.get('/buscarspe/'+usuario.num_control) 
		// .then(function(response)
		// {
		// 	if(response.status === 200)
		// 	{
		// 		usuario = response.data;
		// 		if(usuario.apellido_paterno != null)
		// 		{
		// 			encontrado = 1
		// 			temp.setState({ usuario : usuario, encontrado : encontrado });
		// 		}
		// 		else
		// 		{
		// 			encontrado = 0;
		// 			temp.setState({ encontrado : encontrado });
		// 		}
		// 	}
		// })
		// .catch(error => { });
	}

	consultarMatricula = async()=>
	{
		const { value: formValues } = await Swal.fire({
												title: 'Busqueda de numero de control',
												html:
												'<b>Escribe tu nombre</b>' +
												'<input id="nombre" class="swal2-input">' +
												'<b>Escribe tu apellido paterno</b>' +
												'<input id="ape-pat" class="swal2-input">' +
												'<b>Escribe tu apellido materno</b>' +
												'<input id="ape-mat" class="swal2-input">',
												focusConfirm: false,
												confirmButtonText: 'Buscar',
												preConfirm: () => {
													return [
														document.getElementById('nombre').value,
														document.getElementById('ape-pat').value,
														document.getElementById('ape-mat').value
													]
												}
		});

		if(formValues)
		{
			request.post('/ConsultarMatricula',formValues)
					.then(function(response)
					{
						if(response.status === 200)
						{
							if(response.data)
							{
								Swal.fire('Tu numero de control es: \n\n' + response.data + '\n');
							}
							else
							{
								Swal.fire('No se encontro la información.');
							}			
						}
					})
					.catch(error =>
					{
						Swal.fire('No se encontro la información.');
					}
			);
		}

	}


	render(){

		let { usuario, encontrado } = this.state;

		if(encontrado === 1) return <Descargas id={usuario.id} />;

		return (
			<div className="principal">
	
				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px', width: '60px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					<a className="navbar-brand align-middle md" href="#inicio"> INICIO </a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" style={{ position: 'absolute', right: '40px' }} id="navbarNav">
						<div className="navbar-nav">
							<a className="nav-item nav-link" href="#contacto"> <span className="oi" data-glyph="people" aria-hidden="true"></span> &nbsp;Contacto </a>
						</div>
					</div>
				</nav>

				{/* FORMULARIO DE LOGIN */}

				<div id="inicio" className="inicio container-fluid">
					<div className="row espacio"> </div>
					<div className="row pt-5 pb-5 justify-content-around">
						<div className="col-12 col-sm-11 col-md-5 col-lg-6 col-xl-7 pb-2">
							<div className="row h-100 justify-content-around">
								<div className="col-12 col-sm-11 col-md-10 align-self-center ">
									<p className="kau bl text-left xxl">
										Sistema de Registro Escolar <br/> C.B.T.i.s.&nbsp;#41&nbsp;
									</p>
									<p className="lat am text-left justificadomini md"> 
										SI NO PUEDES ENTRAR A LA PAGINA CON TUS DATOS, ACUDE A LA ESCUELA.
									</p>
								</div>
							</div>
						</div>
						<div className="col-10 col-sm-8 col-md-5 col-lg-4 col-xl-3">
							<form className="animated fadeInRight login container-fluid bl latr gr2 text-left" onSubmit={this.handleFormSubmit}>
								<Row className="pb-4">
									<Col className="bordet"> </Col>
								</Row>
								<Row>
									<Col> <label> <span className="oi am" data-glyph="pencil" aria-hidden="true"></span> Número de control: </label> </Col>
								</Row>
								<Row className="pb-4">
									<Col> <input className="form-control f" type="text" value={usuario.num_control} required name="num_control" placeholder="####" onChange={this.handleInputChange} /> </Col>
								</Row>
								<Row>
									<Col> <label> <span className="oi am" data-glyph="person" aria-hidden="true"></span> CURP: </label> </Col>
								</Row>
								<Row className="pb-4">
									<Col> <input className="form-control f" type="text" value={usuario.curp} name="curp" onChange={this.handleInputChange} /> </Col>
								</Row>
{/*								<Row>
									<Col> <label> <span className="oi am" data-glyph="person" aria-hidden="true"></span> Apellido Paterno: </label> </Col>
								</Row>
								<Row className="pb-4">
									<Col> <input className="form-control f" type="text" value={usuario.apellido_paterno} name="apellido_paterno" onChange={this.handleInputChange} /> </Col>
								</Row>*/}
								<Row className="pt-2">
									<Col> <button className="btn btn-warning btn-block" type="submit"> <b> Ingresar </b> &nbsp; <span className="oi" data-glyph="account-login" aria-hidden="true"> </span> </button> </Col>
								</Row>
								<Row className="pt-2">
									<Col>
										<label>
									 		¿No conoces tu numero de control?,<br/>
									 		Consultalo <button className="comoliga" onClick={(e)=>this.consultarMatricula()}>aqui</button>
									 	</label>
									</Col>
								</Row>
								<Row className="pt-3">
									<Col>
										{
											encontrado === 0 ? 
											<div className="am"> <span className="oi" data-glyph="warning" aria-hidden="true"> </span> <font className="bl"> <i> No coinciden los datos </i> </font> </div> :
											<div className="negra"> . </div>
										}
									</Col>
								</Row>
								<Row className="pt-3">
									<Col className="bordeb"> </Col>
								</Row>
							</form>
						</div>
					</div>
					<div className="row espacio"> </div>
				</div>
						
				{/* PIE DE PAGINA */}
				<PieDePagina1 />

			</div>
		);
	}
}

export default Principal;