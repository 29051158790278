import React from 'react';
import './style.css';
import './animate.min.css';

class Copyright extends React.Component{

	render()
	{
		return (
			<div>
				{/* INFORMACION DE DESARROLLO */}
				<div id="derechos" className="derechos container">
					<div className="row pt-4 pb-4 xs"> 
						<div className="col-12 text-center">
							<i>
								Developed with &nbsp;
								<font className="am"> <span className="oi" data-glyph="heart" aria-hidden="true"> </span> </font> 
								&nbsp;in&nbsp;
								<font className="am"> WeNatives </font>
								&nbsp;By&nbsp; 
								<font className="am"> PapOn </font>; 
							</i>
						</div>
						<div className="col-12 text-center">
							<i> 
								Powered By 
								<font className="am"> React&nbsp;</font>
								&
								<font className="am"> JS&nbsp;</font>;
								&nbsp; Coded with
								<font className="am"> Html&nbsp;</font>;
								&nbsp; Styled with &nbsp;
								<font className="am blogo"> <span className="oi" data-glyph="bold" aria-hidden="true"> </span> </font>
								&nbsp; &
								<font className="am"> CSS&nbsp;</font>;
								&nbsp; Managed with
								<font className="am"> MySql </font>
								&
								<font className="am"> Laravel&nbsp;</font>;
							</i>
						</div>
						<div className="col-12 text-center">
							<i> <font className="am"> Open Iconic </font> — www.useiconic.com/open </i>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Copyright;