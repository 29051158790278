import React from 'react';
import CopyR from './Copyright.js';
import './style.css';
import './animate.min.css';

class Footer2 extends React.Component{

	render()
	{
		return (
			<div>

				{/* INFORMACION DE CONTACTO */}
				<div id="contacto" className="contacto container-fluid">
					<div className="row pt-4 pb-4">
						<div className="col-12 text-center pt-3 md">
							Contáctanos
						</div>
						<div className="col-12 text-center pt-3 sm">
							<font className="am"> 
								<span className="oi" data-glyph="map-marker" aria-hidden="true"></span> &nbsp; Carr. Transpeninsular Km. 115, Ex-Ejido Chapultepec,
							</font>
						</div>
						<div className="col-12 text-center sm">
							<font className="am"> 
								C.P. 22875, Ensenada, Baja California, México.
							</font>
						</div>
						<div className="col-12 text-center pt-3 sm">
							<font className="am"> 
								facebook
								&nbsp; <a href="https://www.facebook.com/Departamento-de-Servicios-Escolares-CBTIS-41-510576019285158"> Departamento-de-Servicios-Escolares-CBTIS-41-510576019285158</a> &nbsp;
								<span className="am"> (inbox)</span>
							</font>
						</div>
						<div className="col-12 text-center pt-3 sm">
							<font className="am"> 
								<span className="oi" data-glyph="phone" aria-hidden="true"></span> &nbsp; 646-198-70-42
							</font>
						</div>
						<div className="col-12 text-center pt-3 sm">
							<font className="am"> 
								<span className="oi" data-glyph="clock" aria-hidden="true"></span> &nbsp; 9:00 a.m. - 2:00 p.m.
							</font>
						</div>
					</div>
				</div>

				{/* INFORMACION DE DESARROLLO */}
				<CopyR />

			</div>
		);
	}

}

export default Footer2;