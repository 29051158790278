import React from 'react';
import PieDePagina1 from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../images/logo1.png';
import './style.css';
import './animate.min.css';

class Fueradeservicio extends React.Component
{
	render()
	{
		return (
			<div className="principal">

				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-2 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					<a className="navbar-brand" href="#inicio"> INICIO </a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
	
				</nav>

				{/* VENTANA INICIAL */}

				<div id="inicio" className="inicio container-fluid">
					<div className="row espacio3"> </div>
					<div className="row pt-5 pb-5 justify-content-around">
						<div className="col-10 align-self-center">
							<p className="kau bl text-left xxl">
								Sistema de Ingreso 2022 C.B.T.i.s.&nbsp;#41
							</p>
							<p className="lat gr3 text-left md">
								ESTAMOS <span className="am"> <b> ACTUALIZANDO EL SITIO </b> </span> PARA TI. <br />
							</p>
						</div>
					</div>
					<div className="row espacio3"> </div>
				</div>

				{/* PIE DE PAGINA */}

				<PieDePagina1 />

			</div>

		);
	}

}

export default Fueradeservicio;